.headerFloating {
  //position: fixed
  top: 75px;
  width: inherit;
  z-index: 100; }

.headerInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 27px;
  background: var(--dark-blue);
  color: #FFF;
  align-items: flex-start;

  @media (max-width: 767px) {
    justify-content: center; } }

.headerInnerCenter {
  justify-content: center;
  padding: 8px 10px 8px 20px; }

.details {
  min-width: 0;
  padding: 15px 0;
  max-width: 60%; }

.transparent {
  .headerFloating {
    position: static; }

  .headerInner {
    background: none;
    padding: 0 27px; } }

.hidden {
  visibility: hidden; }

.rating {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px; }

.buttons {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 84px;
  justify-items: center;
  justify-content: flex-end;
  margin-top: 15px; }

.prevNext {
  display: flex;
  margin-left: 30px; }

.btnNext {
  min-width: 48px; }

.ratingAndButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-self: baseline;
  margin: 20px 0 10px;

  @media (max-width: 767px) {
    margin-top: 13px; } }

.containerButtonsAction {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end; }

.content {
  display: flex;
  flex-direction: column; }

.btnClose {
  position: absolute;
  top: 0;
  right: 0; }

.textMore {
  font-size: 14px;

  @media (max-width: 767px) {
    font-size: 11px; } }

@media (max-width: 767px) {
  .base {
    height: 64px; }

  .headerInner {
    padding: 0 10px 0 20px; }

  .buttons {
    margin: 0;
    padding: 6px 0;
    grid-auto-columns: auto;
    gap: 15px;
    justify-content: center; }

  .prevNext {
    margin-left: auto; } }
