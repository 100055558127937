.base {
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    display: block; } }

.inline {
  composes: base;
  justify-content: flex-start; }

// offset

.offset-10 {
  margin-top: 10px; }

.offset-15 {
  margin-top: 15px; }

.offset-20 {
  margin-top: 20px; }

.offset-25 {
  margin-top: 25px; }

.offset-28 {
  margin-top: 28px; }

.offset-30 {
  margin-top: 30px; }

.offset-35 {
  margin-top: 35px; }

.offset-40 {
  margin-top: 40px; }

.offset-45 {
  margin-top: 45px; }

.offset-50 {
  margin-top: 50px; }

// valign

.valign-center {
  align-items: center; }
