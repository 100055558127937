.main {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  .search-main-wrapper {
    position: relative; }

  .searchIcon {
    position: absolute;
    z-index: 2;
    top: 12px;
    left: 6px; }

  .closeIcon {
    position: absolute;
    z-index: 2;
    top: 12px;
    right: 6px;
    cursor: pointer; }

  .editIcon {
    position: absolute;
    z-index: 2;
    top: 12px;
    right: 26px;
    cursor: pointer; }

  input {
    padding-left: 24px !important; }
  // .main:first-child
  //   margin-left: -10px

  // .search
 }  //   width: 30%
