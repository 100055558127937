.container {
  position: relative;
  overflow: hidden; }

.input {
  position: absolute;
  top: -20px;
  left: -20px; }

.label {
  display: inline-block;
  height: 21px; }

.box {
  float: left;
  width: 19px;
  height: 19px;
  margin-right: 7px;
  border: 1px solid #000;
  border-radius: 4px;
  background: #FFF;
  line-height: 17px;
  text-align: center;

  img {
    vertical-align: middle; } }
