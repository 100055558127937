.containerModal {
  padding: 30px 60px;

  @media (max-width: 767px) {
    padding-right: 30px;
    padding-left: 30px; } }

.dropDownFilter {
  margin-top: 1em; }

.containerIcon {
  display: flex;
  align-items: center;

  svg:first-child {
    margin-bottom: 4px; } }

.conainerButtons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 40px;

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    align-items: center; } }

.cancelSave {
  button:first-child {
    margin-right: 26px; }

  button {
    width: 105px; } }
