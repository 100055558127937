.buttons {
  display: inline-flex; }

.next {
  padding: initial;
  span {
    display: flex;
    padding-right: 6px; }
  svg {
    margin-left: -7px;
    margin-right: -8px; } }

.previous {
  padding: initial;
  span:first-child {
    svg {
      margin-left: -7px;
      margin-right: -8px; } }

  span:last-child {
    span {
      display: flex;
      padding-left: 6px; } } }

@media (max-width: 767px) {
  .buttons {
    margin: 0;
    padding: 6px 0;
    justify-content: center; } }
