.container {
  display: flex;
  align-items: center;
  width: 255px;
  height: 78px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #E2E2E2;
  padding: 16px;

  @media (max-width: 767px) {
    width: 328px;
    margin-top: 16px; } }

.content {
  display: flex;
  flex-direction: column;
  padding-left: 12px; }

.circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 22px;
  opacity: 0.1; }

.iconContainer {
  width: 40px;
  height: 40px;
  position: relative; }

.icon {
  position: absolute;
  top: 10px;
  left: 11px; }

.title {
  font-size: 13px;
  color: #838A97; }

.total {
  font-size: 21px;
  font-weight: 500; }

.text {
  font-size: 12px;
  padding-left: 6px; }
