.container {
  position: absolute;
  z-index: 300;
  top: 0;
  left: 0;
  width: 100%;
  background: #FFF;
  border: 1px solid var(--dusty-gray);
  border-top: none;
  border-radius: 0 0 4px 4px;
  white-space: nowrap; }

.actions {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 10px 10px;
  border-bottom: 1px dashed var(--mercury); }

.ul {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto; }

.ulGroup {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto; }

.li {
  border-bottom: 1px solid var(--mercury);
  padding-left: 25px;

  &:first-child {
    border-top: none; } }

.liGroup {
  font-weight: bold;
  padding: 10px;
  border-top: 1px solid var(--mercury);
  padding-left: 10px;

  &:last-child {
    border-top: none; } }

.liHovered {
  composes: li;
  background: var(--ice); }

.option {
  display: flex;
  width: 100%;
  padding: 10px;
  justify-content: space-between; }
