.layout {
  padding: 14px 2em 0 2em; }

.containerTable {
  border: solid 1px #E2E2E2;
  border-radius: 8px;
  padding-top: 28px;

  @media (max-width: 767px) {
    border: none;
    display: 100%;
    overflow: auto; }

  @media (min-width: 768px) and  (max-width: 1023px) {
    border: none;
    display: 100%;
    overflow: auto; } }

.titleTable {
  font-weight: 500;
  font-size: 21px;
  padding: 28px 0 0 24px; }

.dataTable {
  width: 100%;
  padding-top: 28px;

  @media (max-width: 767px) {
    padding: 1em;
    margin-left: auto;
    margin-right: auto; }

  th {
    padding: 11px;
    background: #F3F8FA;
    color: #838A97;
    font-weight: 500;
    min-width: 2em;
    // border-radius: 5px
    font-size: 14px;

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px; }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px; }

    @media (max-width: 767px) {
      min-width: 2em;
      font-size: 12px; } }

  td {
    padding: 10px 5px;
    // min-width: 4em
    // max-width: 20em
    font-size: 13px;
    border-bottom: 1px solid #E2E2E2;

    @media (max-width: 767px) {
      min-width: 4em;
      max-width: 16em; } }

  tr {
    text-align: left; }

  tr:hover {
    background: var(--deep-blue-light); } }

.loadMore {
  margin-left: auto;
  margin-right: auto;
  width: 10em;

  @media (max-width: 767px) {
    margin-left: 0.5em; } }

.paginationContainer {
  display: flex;
  align-items: center;
  padding: 14px 35px 0 35px;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center; }

  @media (max-width: 767px) {
    padding: 14px 14px 0 28px;

    span {
      font-size: 12px; } } }

.odd {
  background: white; }

.even {
  background: #FAFAFA; }

.btnClose {
  position: absolute;
  top: 0;
  right: 0; }

.containerInfoCard {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center; }

  @media (min-width: 768px) and  (max-width: 1023px) {
    justify-content: space-evenly;
    flex-wrap: wrap;
    height: 180px; } }

.containerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px; }

.containerTitle {
  display: flex;
  flex-direction: column; }

.titleLayout {
  font-size: 24px;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 8px; }

.subTitleLayout {
  font-size: 13px;
  color: #838A97; }

.exportMetric {
  width: 190px;
  height: 48px;
  background: #FFFFFF;
  border: 1px solid #E2E2E2;
  border-radius: 8px; }
