.tdDrag {
  composes: td from './Member.module.sass';
  width: 1px;
  padding-left: 10px;
  font-size: 1px;

  @media (max-width: 767px) {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 44px;
    padding-left: 0;
    align-items: center;
    justify-content: center; } }

.iconDrag {
  display: inline-block;
  width: 14px;

  &:before {
    content: " ";
    display: block;
    height: 2px;
    margin-bottom: 2px;
    border: solid var(--dusty-gray);
    border-width: 1px 0; }

  &:after {
    content: " ";
    display: block;
    border-top: 1px solid var(--dusty-gray); } }
