.container {
  position: absolute;
  z-index: 0;
  right: 0;
  bottom: 74px;
  border-radius: 8px 0 0 8px;
  background: var(--primary-color);
  color: #FFF;
  opacity: 0.7; }

.list {
  margin: 0;
  padding: 0 20px 15px;
  list-style-type: none; }

.duration {
  margin-left: 20px; }
