.container {
  position: relative;
  overflow: hidden; }

.input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;

  &:focus + .label {
    opacity: 0.7; } }

.label {
  position: relative;
  cursor: pointer;

  &:hover {
    opacity: 0.7; } }
