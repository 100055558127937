.tr {
  &:last-child td {
    border-bottom: 1px solid var(--mercury); }

  &.deleting td * {
    opacity: 0.4; }

  &.dragging td * {
    opacity: 0.1; } }

.td {
  padding: 5px 15px 5px 0;
  border-top: 1px solid var(--mercury); }

.tdName {
  composes: td; }

.tdLink {
  composes: td;
  max-width: 100px; }

.tdX {
  composes: td;
  width: 1px;
  padding: 0; }

.tdEdit {
  composes: tdX; }

.tdForm {
  composes: td;
  padding: 30px 0; }

@media (max-width: 767px) {
  .tr {
    display: block;
    position: relative;
    padding-right: 80px;
    padding-left: 44px;
    border-top: 1px solid var(--mercury);

    &:last-child {
      border-bottom: 1px solid var(--mercury);

      td {
        border: none; } } }

  .trForm {
    composes: tr;
    padding-right: 0;
    padding-left: 0; }

  .td {
    display: block;
    border: none;
    padding: 0; }

  .tdName {
    padding-top: 10px; }

  .tdLink {
    max-width: none;
    padding-bottom: 10px; }

  .tdX {
    position: absolute;
    top: 50%;
    right: -10px;
    width: auto;
    margin-top: -22px; }

  .tdEdit {
    right: 40px;
    margin-top: -10px; }

  .tdForm {
    padding: 30px 0; } }
