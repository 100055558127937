.container {
  width: 100%;
  padding-top: 30px; }

.h1 {
  padding: 60px 30px;
  text-align: center;

  @media (max-width: 767px) {
    padding: 0 30px 30px; } }

.form {
  width: 100%;
  max-width: 515px;
  margin: 0 auto;
  padding: 35px 105px 60px;
  background: #FFF;

  @media (max-width: 767px) {
    padding: 35px 30px; } }

.h2 {
  margin-bottom: 50px;
  text-align: center;

  + .intro {
    margin-top: -30px; }

  @media (max-width: 767px) {
    margin-bottom: 30px; } }

.header {
  composes: h2;
  margin: 0 -30px 30px;

  @media (max-width: 767px) {
    margin-right: 0;
    margin-left: 0; } }

.intro {
  margin-bottom: 50px;
  text-align: center;

  &:last-child {
    margin-bottom: 0; } }

.footer {
  padding: 40px 0;
  text-align: center; }
