@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/Helvetica/Helvetica.ttf") format("opentype"); }
@font-face {
  font-family: 'HelveticaBold';
  src: url("../fonts/Helvetica/Helvetica-Bold.ttf") format("opentype"); }
@font-face {
  font-family: 'HelveticaLight';
  src: url("../fonts/Helvetica/helvetica-light-587ebe5a59211.ttf") format("opentype"); }


.container {
  --offset: 20px;
  --negative-offset: -20px;

  display: grid;
  grid-template-columns: unquote('min(calc(60% - 65px), 610px)') auto;
  grid-template-rows: auto;
  grid-template-areas: "intro intro" "photoDetails photoDetails" "overview overview" "instructions instructions" "sponsors sponsors";
  gap: 15px;
  max-width: 1440px;
  margin: 0 auto;
  padding: 70px;

  @media (max-width: 1200px) {
    padding: 35px; }

  @media (max-width: 767px) {
    gap: 50px;
    padding: var(--offset);
    grid-template-columns: 1fr;
    grid-template-areas: "intro" "photoDetails" "overview" "instructions" "sponsors"; } }

.intro {
  grid-area: intro;
  margin: 0 auto;
  text-align: center;
  p {
    max-width: 754px;
    font-size: 24px;
    line-height: 34px; } }

.header {
  margin-top: 40px;
  margin-bottom: 50px;

  .headerH1 {
    letter-spacing: 0;
    font: 46px HelveticaBold;
    margin: -1.0em;
    color: var(--primary-color);
    line-height: 65px;
    font-weight: bold;

    @media (max-width: 767px) {
      font-size: 26px;
      line-height: 42px;
      letter-spacing: -1px; } }

  .headerH2 {
    margin-top: -5px;
    letter-spacing: 0;
    font-weight: 500;
    font-size: 40px;
    line-height: 0.85;
    color: var(--deep-blue);

    @media (max-width: 767px) {
      margin-top: 0;
      font-size: 22px;
      line-height: 34px; } } }

.introButton {
  margin-top: 20px;
  padding-right: 40px;
  padding-left: 40px;

  @media (max-width: 767px) {
    margin-top: 0; } }

.photoDetails {
  grid-area: photoDetails;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  filter: drop-shadow(0 2px 12px #7d98af);

  @media (max-width: 767px) {
    margin: 0 var(--negative-offset); } }

.photo {
  margin-left: 22%;
  margin-right: 22%;

  @media (max-width: 767px) {
    margin-left: 5%;
    margin-right: 5%; }

  img {
    display: block;
    max-width: 100%; } }

.details {
  position: relative;
  overflow: hidden;
  color: #FFF;

  &::before {
    content: " ";
    position: absolute;
    top: -1.3vw;
    left: 50%;
    width: 1320px;
    height: 100%;
    margin-left: -660px;
    background: var(--primary-color);
    transform: rotate(-3deg);

    @media (max-width: 767px) {
      top: -3.2vw; } }

  .detailsInner {
    position: relative;
    padding: 25px 25px 45px; } }

.overview {
  grid-area: overview;
  max-width: 800px;
  margin: 0 auto;
  padding: 30px 0;
  text-align: center;

  h2 {
    letter-spacing: 0;
    font-weight: 500;
    font-size: 36px;
    line-height: 1.35;
    color: var(--deep-blue); }

  p {
    font-family: HelveticaLight, sans-serif;
    font-size: 19px;
    line-height: 1.5em; } }

.instructions {
  grid-area: instructions;
  margin-top: 10px;
  text-align: center;

  @media (max-width: 767px) {
    margin: -30px var(--negative-offset) 0; } }

.instructionsHeading {
  letter-spacing: -0.5px;
  line-height: 1.3;
  font-weight: bold; }

.instructionsForInvestors {
  position: relative;
  margin-bottom: 65px;
  padding: 50px 40px 50px;
  border: solid 1px #cccccc;
  overflow: hidden;

  h1 {
    padding: 50px; }
  //&::before
  //  content: ""
  //  position: absolute
  //  top: 2.5vw
  //  left: 50%
  //  width: 1320px
  //  height: 70px
  //  margin-top: -34px
  //  margin-left: -660px
  //  background: #FFF
  //  border-bottom: 1px solid #cccccc
  //  transform: rotate(-3deg)
  //  transform-origin: 0 0

  //@media (min-width: 1440px)
  //  top: 0
  //  margin-top: -1px

  @media (max-width: 767px) {
    padding-right: var(--offset);
    padding-left: var(--offset);

    &::before {
      border-width: 0 0 55px 100vw; } } }

.instructionsList {
  margin: 60px 0 10px;
  padding: 0;
  list-style: none;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 25px;

  @media (max-width: 767px) {
    margin: 40px 0 0;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    gap: 50px; }

  p {
    margin-right: auto;
    margin-left: auto;
    max-width: 290px;
    line-height: 1.6em; } }

.instructionsStep {
  display: block;
  margin-bottom: 0;
  font-size: 27px;
  font-weight: 500;
  color: var(--primary-color); }

.instructionsIcon {
  margin: 20px 0;
  color: var(--primary-color);
  fill: var(--primary-color); }

.instructionsStepHeading {
  font-size: 24px;
  line-height: 2.08; }

.instructionsForFounders {
  padding: 20px 20px;
  background: var(--deep-blue-light);

  @media (max-width: 767px) {
    padding-right: var(--offset);
    padding-left: var(--offset); }

  p {
    line-height: 1.6em;
    max-width: 300px; }

  h1 {
    padding: 50px; } }

.buttons_mobile {
  margin: 0;
  padding: 6px 0;
  grid-auto-columns: auto;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
  display: grid; }
