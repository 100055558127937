@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/Helvetica/Helvetica.ttf") format("opentype"); }
.base {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .body {
    display: flex;
    flex: 1; }

  .bodyInner {
    width: 100%; } }

.content {
  position: relative;
  height: 100%;
  min-width: 0;
  flex: 1; }

.centered {
  composes: base;

  .bodyInner,
  .content {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center; } }

.centeredSignUpWithLanding {
  composes: base;

  .bodyInner,
  .content {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center; } }

.centeredWhite {
  composes: base;

  .body {
    display: flex;
    flex: 1;
    width: 100%;
    max-width: 950px;
    margin: 30px auto;
    padding: 60px;
    background: #FFF;

    @media (max-width: 767px) {
      padding: 25px 30px; } }

  .bodyInner {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center; } }

.xCenteredWhite {
  composes: centeredWhite;

  .body {
    display: block; }

  .inner {
    display: block; } }

.form {
  composes: xCenteredWhite;

  .body {
    padding-right: 135px;
    padding-left: 135px;

    @media (max-width: 767px) {
      padding: 25px 30px; } } }

.dashboard {
  composes: base;
  background: #FFF;

  .bodyInner {
    width: 925px;
    margin: 0 auto;
    padding: 90px 30px;

    @media (max-width: 767px) {
      width: 100%;
      padding: 40px 30px; } } }

.dashboardWithBlocks {
  composes: dashboard;
  background: none;

  .inner {
    padding: 70px 10px;

    @media (max-width: 767px) {
      padding: 20px 10px; } } }

.dashboardWithSidebar {
  composes: dashboard;

  .bodyInner {
    display: flex;
    width: 1135px;
    padding: 50px 30px; }

  .sidebar {
    width: 180px;
    margin-right: 30px; }

  @media (max-width: 1100px) {
    .bodyInner {
      display: block; }

    .sidebar {
      width: auto;
      margin-right: 0;
      margin-bottom: 50px; } }

  @media (max-width: 767px) {
    .bodyInner {
      padding-top: 30px;
      padding-bottom: 30px; }

    .sidebar {
      margin-bottom: 30px; } } }

.fullHeight {
  composes: base;

  .content {
    height: 100%; } }

.landing {
  background: #FFF;
  font: 18px/1.7 Helvetica, sans-serif; }
