.base {
  position: relative;
  margin-bottom: 6px; }

.sublabel {
  text-transform: none; }

.info {
  margin-left: 5px;
  margin-right: 5px; }
