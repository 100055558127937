.container {
  display: inline-block;
  vertical-align: middle;
  // margin: 0 5px
  font-size: 1px; }

.icon {
  padding: 0 6px; }

// noLeftOverlay

.noLeftOverlay {
  margin-left: 0; }
