.container {
  display: flex; }

.stars {
  display: flex; }

.star {
  unicode-bidi: bidi-override;
  position: relative;

  &::before {
    content: '★';
    opacity: .3; }

  &[title="0"]::after {
    width: 0%; }

  &[title="10"]::after {
    width: 25%; }

  &[title="20"]::after {
    width: 35%; }

  &[title="30"]::after {
    width: 37.5%; }

  &[title="40"]::after {
    width: 45%; }

  &[title="50"]::after {
    width: 50%; }

  &[title="60"]::after {
    width: 65.5%; }

  &[title="70"]::after {
    width: 70%; }

  &[title="80"]::after {
    width: 70%; }

  &[title="90"]::after {
    width: 80%; }

  &::after {
    display: flex;
    color: #FBC02D;
    content: '★';
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: attr(rating);
    overflow: hidden; } }

.value {
  margin-left: 4px; }
