.container {}
+ .container {
  margin-top: 2px; }

.heading {
  width: 100%;
  padding: 5px 10px;
  border-radius: 4px;
  background: var(--ice); }

.segments {
  display: none; }

.segmentsVisible {
  composes: segments;
  display: block; }
