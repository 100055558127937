.container {
  margin-top: 24px;
  text-align: right;

  button:first-child {
    margin-right: 20px; } }

// offset

.offset-30 {
  margin-top: 30px; }
